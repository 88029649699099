import React from 'react';

import footer from './img/footerbg.png';
import footerLogo from './img/logo-footer.png';
import fb from './img/fb.png';
import insta from './img/insta.png';
import youtube from './img/youtube.png';
import customerSupport from './img/customer-support.png';
import mail from './img/mail.png';

import { FloatButton } from 'antd';

import css from './SectionFooter.module.css';
import { NamedLink } from '../../../../components';

const SectionFooter = props => {

  return (
    <div>
      <div className={css.footer}>
        {typeof location !== 'undefined' && location.pathname === '/' && (
          <img src={footer} alt="footer" className={css.backgroundImage} />
        )}

        <div className={css.footerContainer}>
          <div className={css.container}>
            <div className={css.links}>
              <div className={css.linksFull}>
                <NamedLink name="LandingPage" to={{ }}>  
                  <img src={footerLogo} alt="Logo" className={css.footerLogo} width="200" />
                </NamedLink>
                <div className={css.social}>
                  <a href="https://www.facebook.com/Eevent.com.au" target="_blank">
                    <img src={fb} alt="fb" />
                  </a>
                  <a href="https://www.instagram.com/eevent.com.au/" target="_blank">
                    <img src={insta} alt="insta" />
                  </a>
                  <a href="https://www.youtube.com/@eeventcomau" target="_blank">
                    <img src={youtube} alt="youtube" />
                  </a>
                </div>
              </div>
              <div className={css.linksFlex}>
                <h5>About us</h5>
                <ul>
                  <li>
                    <a href="/p/about">Our Story</a>
                  </li>
                  <li>
                    <NamedLink name="HowItWorks" className={css.topbarLink}>How it Works</NamedLink>
                  </li>
                  <li>
                    <NamedLink name="FAQsPage" className={css.topbarLink}>FAQs</NamedLink>
                  </li>
                </ul>
              </div>
              <div className={css.footerRightColumn}>
              <div className={css.linksFlexService} >
                <h5>Featured Services</h5>
                <ul>
                  <li>
                    <NamedLink
                      name="SearchPage"
                      to={{ search: '?pub_categoryLevel1=event-planner-and-coordinator' }}
                    >
                      Event Planners
                    </NamedLink>
                  </li>
                  <li>
                    <NamedLink name="SearchPage" to={{ search: '?pub_categoryLevel1=decorators' }}>
                      Decorators
                    </NamedLink>
                  </li>
                  <li>
                    <NamedLink
                      name="SearchPage"
                      to={{ search: '?pub_categoryLevel1=makeup-and-hair-artists' }}
                    >
                      Makeup & Hair Artists
                    </NamedLink>
                  </li>
                  <li>
                    <NamedLink
                      name="SearchPage"
                      to={{ search: '?pub_categoryLevel1=photographers-videographers' }}
                    >
                      Photographers
                    </NamedLink>
                  </li>
                  <li>
                    <NamedLink
                      name="SearchPage"
                      to={{ search: '?pub_categoryLevel1=celebrant-services' }}
                    >
                      Celebrants
                    </NamedLink>
                  </li>
                </ul>
              </div>
              <div className={css.linksFull}>
                <div className={css.iconLink}>
                  <div>
                    <img src={customerSupport} alt="customerSupport" />
                  </div>
                  <div>
                    <h5>Customer Support</h5>
                    <a href="mailto:info@eevent.com.au">info@eevent.com.au</a>
                  </div>
                </div>
                {/* <a href="/contact-us">
                  <div className={css.iconLink}>
                    <div>
                      <img src={mail} alt="mail" />
                    </div>
                    <div>
                      <h5>Contact Us</h5>
                    </div>
                  </div>
                </a> */}
              </div>
              </div>
            </div>
            <div className={css.copy}>
              <div>Copyright © Eevent.com.au</div>
              <div>
                <a href="/privacy-policy">Privacy Policy</a> |{' '}
                <a href="/terms-of-service">Terms of Service</a>
              </div>
            </div>
            <div className={css.goTop}>
              {/* <a href='#top'><img src={gotop} alt="go top" /></a> */}
              <FloatButton.BackTop />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};


export default SectionFooter;
