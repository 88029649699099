import React, { useEffect, useState } from 'react';

const OwlCarousel = React.lazy(() => import('react-owl-carousel'));
// import 'owl.carousel/dist/assets/owl.carousel.css';
// import 'owl.carousel/dist/assets/owl.theme.default.css';
import Sydney from './img/sydney.jpg';
import Melbourne from './img/Melbourne.jpg';
import Brisbane from './img/Brisbane.jpg';
import GoldCoast from './img/GoldCoast.jpg';
import Adelaide from './img/Adelaide.jpg';
import Canberra from './img/Canberra.jpg';
import Perth from './img/Perth.jpg';
import Hobart from './img/Hobart.jpg';
import Darwin from './img/Darwin.jpg';
import locBg from './img/location-bg.jpg';
import css from './SectionExploreListings.module.css';
import Sydney1 from './img/sydney1.jpg';
import Melbourne1 from './img/Melbourne1.jpg';
import Brisbane1 from './img/Brisbane1.jpg';
import GoldCoast1 from './img/GoldCoast1.jpg';
import Adelaide1 from './img/Adelaide1.jpg';
import Canberra1 from './img/Canberra1.jpg';
import Perth1 from './img/Perth1.jpg';
import Hobart1 from './img/Hobart1.jpg';
import Darwin1 from './img/Darwin1.jpg';

import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { createResourceLocatorString } from '../../../../util/routes';
import { useRouteConfiguration } from '../../../../context/routeConfigurationContext';
import { NamedLink } from '../../../../components';

// Section component for a website's hero section
// The Section Hero doesn't have any Blocks by default, all the configurations are made in the Section Hero settings
const SectionExploreListings = props => {
  const history = useHistory();
  const routeConfiguration = useRouteConfiguration();

  const { listingsLocationCount } = props;

  const locationOptions = {
    responsive: {
      200: {
        items: 2,
        margin: 0,
        center: true,
        loop: true,
        nav: false,
        dots: true,
        autoplay: false,
      },
      400: {
        items: 2,
      },
      700: {
        items: 4,
      },
      1000: {
        items: 3,
      },
    },
    items: 2,
    className: 'owl-loc',
    loop: false,
    nav: true,
    autoplay: false,
    dots: false,
    margin: 8,
    navText: [
      '<svg viewBox="64 64 896 896" focusable="false" data-icon="arrow-left" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M872 474H286.9l350.2-304c5.6-4.9 2.2-14-5.2-14h-88.5c-3.9 0-7.6 1.4-10.5 3.9L155 487.8a31.96 31.96 0 000 48.3L535.1 866c1.5 1.3 3.3 2 5.2 2h91.5c7.4 0 10.8-9.2 5.2-14L286.9 550H872c4.4 0 8-3.6 8-8v-60c0-4.4-3.6-8-8-8z"></path></svg>',
      '<svg viewBox="64 64 896 896" focusable="false" data-icon="arrow-right" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M869 487.8L491.2 159.9c-2.9-2.5-6.6-3.9-10.5-3.9h-88.5c-7.4 0-10.8 9.2-5.2 14l350.2 304H152c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h585.1L386.9 854c-5.6 4.9-2.2 14 5.2 14h91.5c1.9 0 3.8-.7 5.2-2L869 536.2a32.07 32.07 0 000-48.4z"></path></svg>',
    ],
  };

  const [isClient, setIsClient] = useState(false);
  useEffect(() => {
    // Ensure this code only runs on the client
    setIsClient(true);
  }, []);

  const redirectToSearchPage = () => {
    history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}));
  };

  const formatCount = (count) => {
    if (count > 999) {
      return '999+';
    }
    return count;
  }

  return (
    <div className={css.location}>
      <div className={css.content}>
        <h2 className={css.VisibleDesktop}>Explore Services in Entire Australia</h2>


        <p>
          Experience unparalleled convenience with Eevent! Hire exceptional services right in your local city or from anywhere across Australia!
        </p>
        <p>
          Whether your events are hosted in vibrant streets of Sydney or in the picturesque landscapes of Tasmania.  Eevent makes it easy to find the right services across the nation.
        </p>
        <div>
          <button type="button" onClick={redirectToSearchPage} className={css.brandButton}>
            All Locations
          </button>
        </div>

        <div className={css.serveiceTitle}>
          <h2>Services in Australia</h2>
          <a type="button" className={css.btnLink} onClick={redirectToSearchPage}>
            See all
          </a>
        </div>
        <div className={css.VisibleMobile}>
          <div className={css.locMobile}>
            <NamedLink
              name="SearchPage"
              to={{
                search:
                  '?address=Sydney%2C%20New%20South%20Wales%2C%20Australia&bounds=-33.562644%2C151.369884%2C-34.117175%2C150.520934',
              }}
            >
              <div className={css.locMobileCard}>

                <img src={Sydney1} alt="Sydney" />

                <h5>Sydney</h5>

                <span>{formatCount(listingsLocationCount[0])}</span>

              </div>
            </NamedLink>

            <NamedLink
              name="SearchPage"
              to={{
                search:
                  '?address=Melbourne%2C%20Victoria%2C%20Australia&bounds=-37.50211%2C145.5507%2C-38.451455%2C144.558953',
              }}
            >
              <div className={css.locMobileCard}>

                <img src={Melbourne1} alt="Melbourne" />

                <h5>Melbourne</h5>

                <span>{formatCount(listingsLocationCount[1])}</span>

              </div>
            </NamedLink>

            <NamedLink
              name="SearchPage"
              to={{
                search:
                  '?address=Brisbane%2C%20Queensland%2C%20Australia&bounds=-26.96318%2C153.331653%2C-27.778919%2C152.667538',
              }}
            >
              <div className={css.locMobileCard}>

                <img src={Brisbane1} alt="Brisbane" />

                <h5>Brisbane</h5>

                <span>{formatCount(listingsLocationCount[2])}</span>

              </div>
            </NamedLink>

            <NamedLink
              name="SearchPage"
              to={{
                search:
                  '?address=Gold%20Coast%2C%20Queensland%2C%20Australia&bounds=-27.708149%2C153.572698%2C-28.189258%2C153.186512',
              }}
            >
              <div className={css.locMobileCard}>

                <img src={GoldCoast1} alt="Gold Coast" />

                <h5>Gold Coast</h5>

                <span>{formatCount(listingsLocationCount[3])}</span>

              </div>
            </NamedLink>

            <NamedLink
              name="SearchPage"
              to={{
                search:
                  '?address=Adelaide%2C%20South%20Australia%2C%20Australia&bounds=-34.647794%2C138.772949%2C-35.348956%2C138.4152',
              }}
            >
              <div className={css.locMobileCard}>

                <img src={Adelaide1} alt="Adelaide" />

                <h5>Adelaide</h5>

                <span>{formatCount(listingsLocationCount[4])}</span>

              </div>
            </NamedLink>

            <NamedLink
              name="SearchPage"
              to={{
                search:
                  '?address=Canberra%2C%20Australian%20Capital%20Territory%2C%20Australia&bounds=-35.124403%2C149.263516%2C-35.592776%2C148.968683',
              }}
            >
              <div className={css.locMobileCard}>

                <img src={Canberra1} alt="Canberra" />

                <h5>Canberra</h5>

                <span>{formatCount(listingsLocationCount[5])}</span>

              </div>
            </NamedLink>

            <NamedLink
              name="SearchPage"
              to={{
                search:
                  '?address=Perth%2C%20Western%20Australia%2C%20Australia&bounds=-31.568682%2C116.32375%2C-32.659495%2C115.589016',
              }}
            >
              <div className={css.locMobileCard}>

                <img src={Perth1} alt="Perth" />

                <h5>Perth</h5>

                <span>{formatCount(listingsLocationCount[6])}</span>

              </div>
            </NamedLink>

            <NamedLink
              name="SearchPage"
              to={{
                search:
                  '?address=Hobart%2C%20Tasmania%2C%20Australia&bounds=-42.675823%2C147.617486%2C-43.025269%2C147.147812',
              }}
            >
              <div className={css.locMobileCard}>

                <img src={Hobart1} alt="Hobart" />

                <h5>Hobart</h5>

                <span>{formatCount(listingsLocationCount[7])}</span>

              </div>
            </NamedLink>

            <NamedLink
              name="SearchPage"
              to={{
                search:
                  '/s?address=Darwin%2C%20Northern%20Territory%2C%20Australia&bounds=-12.303717%2C131.051509%2C-12.547175%2C130.789437',
              }}
            >
              <div className={css.locMobileCard}>

                <img src={Darwin1} alt="Darwin" />

                <h5>Darwin</h5>

                <span>{formatCount(listingsLocationCount[8])}</span>

              </div>
            </NamedLink>

          </div>
        </div>
      </div>
      <div className={css.slider}>
        <img src={locBg} alt="" className={css.locBg} />

        {isClient && (
          <React.Suspense fallback={<div>Loading carousel...</div>}>
            <OwlCarousel {...locationOptions}>
              <NamedLink
                name="SearchPage"
                to={{
                  search:
                    '?address=Sydney%2C%20New%20South%20Wales%2C%20Australia&bounds=-33.562644%2C151.369884%2C-34.117175%2C150.520934',
                }}
              >
                <div className={css.locCard}>
                  <div className={css.overlay}>&nbsp;</div>
                  <img src={Sydney} alt="Sydney" />
                  <div className={css.content}>
                    <h5>Sydney</h5>
                    <div className={css.footer}>
                      <div>
                        <span>{formatCount(listingsLocationCount[0])}</span>
                      </div>
                      <div>
                        <div>Services</div>
                        <div>
                          <small>Explore</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </NamedLink>

              <NamedLink
                name="SearchPage"
                to={{
                  search:
                    '?address=Melbourne%2C%20Victoria%2C%20Australia&bounds=-37.50211%2C145.5507%2C-38.451455%2C144.558953',
                }}
              >
                <div className={css.locCard}>
                  <div className={css.overlay}>&nbsp;</div>
                  <img src={Melbourne} alt="Melbourne" />
                  <div className={css.content}>
                    <h5>Melbourne</h5>
                    <div className={css.footer}>
                      <div>
                        <span>{formatCount(listingsLocationCount[1])}</span>
                      </div>
                      <div>
                        <div>Services</div>
                        <div>
                          <small>Explore</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </NamedLink>

              <NamedLink
                name="SearchPage"
                to={{
                  search:
                    '?address=Brisbane%2C%20Queensland%2C%20Australia&bounds=-26.96318%2C153.331653%2C-27.778919%2C152.667538',
                }}
              >
                <div className={css.locCard}>
                  <div className={css.overlay}>&nbsp;</div>
                  <img src={Brisbane} alt="Brisbane" />
                  <div className={css.content}>
                    <h5>Brisbane</h5>
                    <div className={css.footer}>
                      <div>
                        <span>{formatCount(listingsLocationCount[2])}</span>
                      </div>
                      <div>
                        <div>Services</div>
                        <div>
                          <small>Explore</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </NamedLink>

              <NamedLink
                name="SearchPage"
                to={{
                  search:
                    '?address=Gold%20Coast%2C%20Queensland%2C%20Australia&bounds=-27.708149%2C153.572698%2C-28.189258%2C153.186512',
                }}
              >
                <div className={css.locCard}>
                  <div className={css.overlay}>&nbsp;</div>
                  <img src={GoldCoast} alt="Gold Coast" />
                  <div className={css.content}>
                    <h5>Gold Coast</h5>
                    <div className={css.footer}>
                      <div>
                        <span>{formatCount(listingsLocationCount[3])}</span>
                      </div>
                      <div>
                        <div>Services</div>
                        <div>
                          <small>Explore</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </NamedLink>

              <NamedLink
                name="SearchPage"
                to={{
                  search:
                    '?address=Adelaide%2C%20South%20Australia%2C%20Australia&bounds=-34.647794%2C138.772949%2C-35.348956%2C138.4152',
                }}
              >
                <div className={css.locCard}>
                  <div className={css.overlay}>&nbsp;</div>
                  <img src={Adelaide} alt="Adelaide" />
                  <div className={css.content}>
                    <h5>Adelaide</h5>
                    <div className={css.footer}>
                      <div>
                        <span>{formatCount(listingsLocationCount[4])}</span>
                      </div>
                      <div>
                        <div>Services</div>
                        <div>
                          <small>Explore</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </NamedLink>

              <NamedLink
                name="SearchPage"
                to={{
                  search:
                    '?address=Canberra%2C%20Australian%20Capital%20Territory%2C%20Australia&bounds=-35.124403%2C149.263516%2C-35.592776%2C148.968683',
                }}
              >
                <div className={css.locCard}>
                  <div className={css.overlay}>&nbsp;</div>
                  <img src={Canberra} alt="Canberra" />
                  <div className={css.content}>
                    <h5>Canberra</h5>
                    <div className={css.footer}>
                      <div>
                        <span>{formatCount(listingsLocationCount[5])}</span>
                      </div>
                      <div>
                        <div>Services</div>
                        <div>
                          <small>Explore</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </NamedLink>

              <NamedLink
                name="SearchPage"
                to={{
                  search:
                    '?address=Perth%2C%20Western%20Australia%2C%20Australia&bounds=-31.568682%2C116.32375%2C-32.659495%2C115.589016',
                }}
              >
                <div className={css.locCard}>
                  <div className={css.overlay}>&nbsp;</div>
                  <img src={Perth} alt="Perth" />
                  <div className={css.content}>
                    <h5>Perth</h5>
                    <div className={css.footer}>
                      <div>
                        <span>{formatCount(listingsLocationCount[6])}</span>
                      </div>
                      <div>
                        <div>Services</div>
                        <div>
                          <small>Explore</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </NamedLink>

              <NamedLink
                name="SearchPage"
                to={{
                  search:
                    '?address=Hobart%2C%20Tasmania%2C%20Australia&bounds=-42.675823%2C147.617486%2C-43.025269%2C147.147812',
                }}
              >
                <div className={css.locCard}>
                  <div className={css.overlay}>&nbsp;</div>
                  <img src={Hobart} alt="Hobart" />
                  <div className={css.content}>
                    <h5>Hobart</h5>
                    <div className={css.footer}>
                      <div>
                        <span>{formatCount(listingsLocationCount[7])}</span>
                      </div>
                      <div>
                        <div>Services</div>
                        <div>
                          <small>Explore</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </NamedLink>

              <NamedLink
                name="SearchPage"
                to={{
                  search:
                    '/s?address=Darwin%2C%20Northern%20Territory%2C%20Australia&bounds=-12.303717%2C131.051509%2C-12.547175%2C130.789437',
                }}
              >
                <div className={css.locCard}>
                  <div className={css.overlay}>&nbsp;</div>
                  <img src={Darwin} alt="Darwin" />
                  <div className={css.content}>
                    <h5>Darwin</h5>
                    <div className={css.footer}>
                      <div>
                        <span>{formatCount(listingsLocationCount[8])}</span>
                      </div>
                      <div>
                        <div>Services</div>
                        <div>
                          <small>Explore</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </NamedLink>

              <NamedLink
                name="SearchPage"
                to={{
                  search:
                    '?address=Sydney%2C%20New%20South%20Wales%2C%20Australia&bounds=-33.562644%2C151.369884%2C-34.117175%2C150.520934',
                }}
                className={css.locCardHideMobile}
              >
                <div className={css.locCard}>
                  <div className={css.overlay}>&nbsp;</div>
                  <img src={Sydney} alt="Sydney" />
                  <div className={css.content}>
                    <h5>Sydney</h5>
                    <div className={css.footer}>
                      <div>
                        <span>{formatCount(listingsLocationCount[0])}</span>
                      </div>
                      <div>
                        <div>Services</div>
                        <div>
                          <small>Explore</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </NamedLink>
            </OwlCarousel>
          </React.Suspense>
        )}
      </div>
    </div>
  );
};

export default SectionExploreListings;
