import React from 'react';

import { IconSpinner, LayoutComposer } from '../../components/index.js';
import TopbarContainer from '../../containers/TopbarContainer/TopbarContainer.js';
import FooterContainer from '../FooterContainer/FooterContainer.js';

import { validProps } from './Field';

import SectionBuilder from './SectionBuilder/SectionBuilder.js';
import StaticPage from './StaticPage.js';

import css from './PageBuilder.module.css';
import SectionOurTopServices from './SectionBuilder/SectionOurTopServices/SectionOurTopServices.js';
import SectionExploreListings from './SectionBuilder/SectionExploreListings/SectionExploreListings.js';
import SectionTopBookedListings from './SectionBuilder/SectionTopBookedListings/SectionTopBookedListings.js';
import SectionHowItWorks from './SectionBuilder/SectionHowItWorks/SectionHowItWorks.js';
import TopHeader from './SectionBuilder/TopHeader/TopHeader.js';

import email from './img/email.png';
import home from './img/home.png';
import plus from './img/plus.png';
import search from './img/search.png';
import user from './img/user.png';

const getMetadata = (meta, schemaType, fieldOptions) => {
  const { pageTitle, pageDescription, socialSharing } = meta;

  // pageTitle is used for <title> tag in addition to page schema for SEO
  const title = validProps(pageTitle, fieldOptions)?.content;
  // pageDescription is used for different <meta> tags in addition to page schema for SEO
  const description = validProps(pageDescription, fieldOptions)?.content;
  // Data used when the page is shared in social media services
  const openGraph = validProps(socialSharing, fieldOptions);
  // We add OpenGraph image as schema image if it exists.
  const schemaImage = openGraph?.images1200?.[0]?.url;
  const schemaImageMaybe = schemaImage ? { image: [schemaImage] } : {};
  const isArticle = ['Article', 'NewsArticle', 'TechArticle'].includes(schemaType);
  const schemaHeadlineMaybe = isArticle ? { headline: title } : {};

  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org (This template uses JSON-LD format)
  //
  // In addition to this schema data for search engines, src/components/Page/Page.js adds some extra schemas
  // Read more about schema:
  // - https://schema.org/
  // - https://developers.google.com/search/docs/advanced/structured-data/intro-structured-data
  const pageSchemaForSEO = {
    '@context': 'http://schema.org',
    '@type': schemaType || 'WebPage',
    description: description,
    name: title,
    ...schemaHeadlineMaybe,
    ...schemaImageMaybe,
  };

  return {
    title,
    description,
    schema: pageSchemaForSEO,
    socialSharing: openGraph,
  };
};

const LoadingSpinner = () => {
  return (
    <div className={css.loading}>
      <IconSpinner delay={600} />
    </div>
  );
};

//////////////////
// Page Builder //
//////////////////

/**
 * PageBuilder can be used to build content pages using page-asset.json.
 *
 * Note: props can include a lot of things that depend on
 * - pageAssetsData: json asset that contains instructions how to build the page content
 *   - asset should contain an array of _sections_, which might contain _fields_ and an array of _blocks_
 *     - _blocks_ can also contain _fields_
 * - fallbackPage: component. If asset loading fails, this is used instead.
 * - options: extra mapping of 3 level of sub components
 *   - sectionComponents: { ['my-section-type']: { component: MySection } }
 *   - blockComponents: { ['my-component-type']: { component: MyBlock } }
 *   - fieldComponents: { ['my-field-type']: { component: MyField, pickValidProps: data => Number.isInteger(data.content) ? { content: data.content } : {} }
 *     - fields have this pickValidProps as an extra requirement for data validation.
 * - pageProps: props that are passed to src/components/Page/Page.js component
 *
 * @param {Object} props
 * @returns page component
 */
const PageBuilder = props => {
  const {
    pageAssetsData,
    inProgress,
    error,
    bookingsCountListings,
    averageRatingsListings,
    fallbackPage,
    schemaType,
    options,
    currentPage,
    listingsLocationCount,
    ...pageProps
  } = props;

  if (!pageAssetsData && fallbackPage && !inProgress && error) {
    return fallbackPage;
  }

  // Page asset contains UI info and metadata related to it.
  // - "sections" (data that goes inside <body>)
  // - "meta" (which is data that goes inside <head>)
  const { sections = [], meta = {} } = pageAssetsData || {};
  const pageMetaProps = getMetadata(meta, schemaType, options?.fieldComponents);

  const layoutAreas = `
    topbar
    main
    footer
  `;

  return (
    <StaticPage {...pageMetaProps} {...pageProps}>
      <LayoutComposer areas={layoutAreas} className={css.layout}>
        {props => {
          const { Topbar, Main, Footer } = props;
          return (
            <>
              <Topbar as="header" className={css.topbar}>
                <TopbarContainer currentPage={currentPage} />
              </Topbar>
              
              <Main as="main" className={css.main} >
                <div style={{minHeight:'800px'}}>
                {sections.length === 0 ? (
                  <LoadingSpinner />
                ) : (
                  <>
                  
                  {/* <TopHeader /> */}
                    <SectionBuilder sections={sections} options={options} />
                    {sections[0]?.sectionType == 'hero' ? 
                    <>
                    <SectionOurTopServices />
                    { listingsLocationCount &&  listingsLocationCount.length != 0 ? 
                    <SectionExploreListings listingsLocationCount={listingsLocationCount}/>
                    : null }
                    <SectionTopBookedListings />
                    {/* Commenting this section for 5th October launch  */}
                    {/* <SectionHowItWorks /> */}
                    </>
                    : null }
                  </>
                )}
                </div>
              </Main>
              <Footer>
                <FooterContainer footerShow={true}/>
              </Footer>
              {/* <div className={css.mobileToolbar}>
                  <a href="/"><img src={home} alt="" /></a>
                  <a href="/s"><img src={search} alt="" /></a>
                  <a href="l/draft/00000000-0000-0000-0000-000000000000/new/details" className={css.middleToolbar}><img src={plus} alt="" /></a>
                  <a href="/contact-us"><img src={email} alt="" /></a>
                  <a href="/login"><img src={user} alt="" /></a>
              </div> */}
            </>
          );
        }}
      </LayoutComposer>
    </StaticPage>
  );
};

export { LayoutComposer, StaticPage, SectionBuilder };

export default PageBuilder;
