import React, { useEffect, useState } from 'react';
const OwlCarousel = React.lazy(() => import('react-owl-carousel'));
// import 'owl.carousel/dist/assets/owl.carousel.css';
// import 'owl.carousel/dist/assets/owl.theme.default.css';


const our = 'https://eeventstorage.blob.core.windows.net/oursection/Our.png';
const Wedding = 'https://eeventstorage.blob.core.windows.net/oursection/wedding.svg';
const Marriage = 'https://eeventstorage.blob.core.windows.net/oursection/Marriage.svg';
const WeddingReception = 'https://eeventstorage.blob.core.windows.net/oursection/WeddingReception.svg';
const Birthday = 'https://eeventstorage.blob.core.windows.net/oursection/Birthday.svg';
const Graduation = 'https://eeventstorage.blob.core.windows.net/oursection/Graduation.svg';
const BridalShower = 'https://eeventstorage.blob.core.windows.net/oursection/BridalShower.svg';
const BabyShower = 'https://eeventstorage.blob.core.windows.net/oursection/BabyShower.svg';
const Anniversary = 'https://eeventstorage.blob.core.windows.net/oursection/Anniversary.svg';
const Engagement = 'https://eeventstorage.blob.core.windows.net/oursection/Engagement.svg';
const Retirement = 'https://eeventstorage.blob.core.windows.net/oursection/Retirement.svg';
const HouseWarming = 'https://eeventstorage.blob.core.windows.net/oursection/HouseWarming.svg';
const CocktailParty = 'https://eeventstorage.blob.core.windows.net/oursection/CocktailParty.svg';
const CorporateEvent = 'https://eeventstorage.blob.core.windows.net/oursection/CorporateEvent.svg';
const Christmas = 'https://eeventstorage.blob.core.windows.net/oursection/Christmas.svg';
const Religious = 'https://eeventstorage.blob.core.windows.net/oursection/Religious.svg';
const BachelorParty = 'https://eeventstorage.blob.core.windows.net/oursection/BachelorParty.svg';
const BacheloretteParty = 'https://eeventstorage.blob.core.windows.net/oursection/BacheloretteParty.svg';
const NewYear = 'https://eeventstorage.blob.core.windows.net/oursection/NewYear.svg';
const Halloween = 'https://eeventstorage.blob.core.windows.net/oursection/Halloween.svg';
const Reunion = 'https://eeventstorage.blob.core.windows.net/oursection/Reunion.svg';
const Formals = 'https://eeventstorage.blob.core.windows.net/oursection/Formals.svg';
const HensNight = 'https://eeventstorage.blob.core.windows.net/oursection/HensNight.svg';
const BucksNight = 'https://eeventstorage.blob.core.windows.net/oursection/BucksNight.svg';
const NamingCeremony = 'https://eeventstorage.blob.core.windows.net/oursection/NamingCeremony.svg';
const CommitmentCeremony = 'https://eeventstorage.blob.core.windows.net/oursection/CommitmentCeremony.svg';
const Photoshoot = 'https://eeventstorage.blob.core.windows.net/oursection/Photoshoot.svg';
const TradeShow = 'https://eeventstorage.blob.core.windows.net/oursection/TradeShow.svg';
const CharityEvent = 'https://eeventstorage.blob.core.windows.net/oursection/CharityEvent.svg';
const VIPReception = 'https://eeventstorage.blob.core.windows.net/oursection/VIPReception.svg';
const PrivateDinner = 'https://eeventstorage.blob.core.windows.net/oursection/PrivateDinner.svg';
const TeamBuilding = 'https://eeventstorage.blob.core.windows.net/oursection/TeamBuilding.svg';
const FamilyFunDay = 'https://eeventstorage.blob.core.windows.net/oursection/FamilyFunDay.svg';
const HennaParty = 'https://eeventstorage.blob.core.windows.net/oursection/HennaParty.svg';
const Festival = 'https://eeventstorage.blob.core.windows.net/oursection/Festival.svg';
const JustGetTogether = 'https://eeventstorage.blob.core.windows.net/oursection/JustGetTogether.svg';
const Highlight = 'https://eeventstorage.blob.core.windows.net/oursection/highlight.png';
const Makeup = 'https://eeventstorage.blob.core.windows.net/oursection/Makeup-and-Hair-Artists.jpg';
const Photographers = 'https://eeventstorage.blob.core.windows.net/oursection/Photographers-Videographers.jpg';
const Cook = 'https://eeventstorage.blob.core.windows.net/oursection/cook-chef.jpg';
const Cakes = 'https://eeventstorage.blob.core.windows.net/oursection/cakes.jpg';
const HennaArtists = 'https://eeventstorage.blob.core.windows.net/oursection/henna-artists.jpg';
const Venue = 'https://eeventstorage.blob.core.windows.net/oursection/venue.jpg';
const Decorators = 'https://eeventstorage.blob.core.windows.net/oursection/decorators.jpg';
const Catering = 'https://eeventstorage.blob.core.windows.net/oursection/catering.jpg';
const EventPlanner = 'https://eeventstorage.blob.core.windows.net/oursection/eventplanner.jpg';
const Entertainer = 'https://eeventstorage.blob.core.windows.net/oursection/entertainer.jpg';
const Hospitality = 'https://eeventstorage.blob.core.windows.net/oursection/hospitality.jpg';
const Celebrant = 'https://eeventstorage.blob.core.windows.net/oursection/celebrant.jpg';
const Transportation = 'https://eeventstorage.blob.core.windows.net/oursection/transportation.jpg';
const PartyProps = 'https://eeventstorage.blob.core.windows.net/oursection/partyprops.jpg';
import { ArrowRightOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import css from './SectionOurTopServices.module.css';
import { NamedLink } from '../../../../components';

// Section component for a website's hero section
// The Section Hero doesn't have any Blocks by default, all the configurations are made in the Section Hero settings
const SectionOurTopServices = props => {
  const eventOptions = {
    responsive: {
      300: {
        items: 4,
        nav: false,
      },
      700: {
        items: 5,
        nav: false,
      },
      1000: {
        items: 9,
      },
      1400: {
        items: 10,
      },
    },
    items: 3,
    stagePadding: 15,
    className: 'owl-scoll',
    loop: true,
    autoplayHoverPause: true,
    nav: true,
    autoplay: true,
    dots: false,
    margin: 8,
    navText: [
      '<svg viewBox="64 64 896 896" focusable="false" data-icon="arrow-left" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M872 474H286.9l350.2-304c5.6-4.9 2.2-14-5.2-14h-88.5c-3.9 0-7.6 1.4-10.5 3.9L155 487.8a31.96 31.96 0 000 48.3L535.1 866c1.5 1.3 3.3 2 5.2 2h91.5c7.4 0 10.8-9.2 5.2-14L286.9 550H872c4.4 0 8-3.6 8-8v-60c0-4.4-3.6-8-8-8z"></path></svg>',
      '<svg viewBox="64 64 896 896" focusable="false" data-icon="arrow-right" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M869 487.8L491.2 159.9c-2.9-2.5-6.6-3.9-10.5-3.9h-88.5c-7.4 0-10.8 9.2-5.2 14l350.2 304H152c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h585.1L386.9 854c-5.6 4.9-2.2 14 5.2 14h91.5c1.9 0 3.8-.7 5.2-2L869 536.2a32.07 32.07 0 000-48.4z"></path></svg>',
    ],
  };

  const serviceOptions = {
    responsive: {
      300: {
        items: 2,
      },
      700: {
        items: 3,
      },
      1000: {
        items: 5,
      },
      1400: {
        items: 6,
      },
    },
    items: 2,
    className: 'owl-service',
    loop: true,
    //rewind: true,
    autoplay: true,
    nav: true,
    center: true,
    dots: false,
    navText: [
      '<svg viewBox="64 64 896 896" focusable="false" data-icon="arrow-left" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M872 474H286.9l350.2-304c5.6-4.9 2.2-14-5.2-14h-88.5c-3.9 0-7.6 1.4-10.5 3.9L155 487.8a31.96 31.96 0 000 48.3L535.1 866c1.5 1.3 3.3 2 5.2 2h91.5c7.4 0 10.8-9.2 5.2-14L286.9 550H872c4.4 0 8-3.6 8-8v-60c0-4.4-3.6-8-8-8z"></path></svg>',
      '<svg viewBox="64 64 896 896" focusable="false" data-icon="arrow-right" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M869 487.8L491.2 159.9c-2.9-2.5-6.6-3.9-10.5-3.9h-88.5c-7.4 0-10.8 9.2-5.2 14l350.2 304H152c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h585.1L386.9 854c-5.6 4.9-2.2 14 5.2 14h91.5c1.9 0 3.8-.7 5.2-2L869 536.2a32.07 32.07 0 000-48.4z"></path></svg>',
    ],
  };

  const [isClient, setIsClient] = useState(false);
  useEffect(() => {
    // Ensure this code only runs on the client
    setIsClient(true);
  }, []);

  return (
    <div>
      <div className={css.allEvents}>
        <div className={css.containerFluid}>
          {isClient && (
            <React.Suspense fallback={
              <div className={css.eventContainer}>
                <NamedLink name="SearchPage" to={{ search: '?pub_events=has_all%3Awedding' }}>
                  <div className={css.itemEvent}><div className={css.itemImg}>
                    <img src={Wedding} alt="Wedding" /></div>
                    <span>Wedding</span>
                  </div>
                </NamedLink>
                <NamedLink name="SearchPage" to={{ search: '?pub_events=has_all%3Amarriage' }}>
                  <div className={css.itemEvent}><div className={css.itemImg}>
                    <img src={Marriage} alt="Marriage" /></div>
                    <span>Marriage</span>
                  </div>
                </NamedLink>
                <NamedLink
                  name="SearchPage"
                  to={{ search: '?pub_events=has_all%3Awedding-reception' }}
                >
                  <div className={css.itemEvent}><div className={css.itemImg}>
                    <img src={WeddingReception} alt="Wedding Reception" /></div>
                    <span>Wedding Reception</span>
                  </div>
                </NamedLink>
                <NamedLink name="SearchPage" to={{ search: '?pub_events=has_all%3Abirthday' }}>
                  <div className={css.itemEvent}><div className={css.itemImg}>
                    <img src={Birthday} alt="Birthday" /></div>
                    <span>Birthday</span>
                  </div>
                </NamedLink>
                <NamedLink name="SearchPage" to={{ search: '?pub_events=has_all%3Agraduation' }}>
                  <div className={css.itemEvent}><div className={css.itemImg}>
                    <img src={Graduation} alt="Graduation" /></div>
                    <span>Graduation</span>
                  </div>
                </NamedLink>
                <NamedLink name="SearchPage" to={{ search: '?pub_events=has_all%3Abridal-shower' }}>
                  <div className={css.itemEvent}><div className={css.itemImg}>
                    <img src={BridalShower} alt="Bridal Shower" /></div>
                    <span>Bridal Shower</span>
                  </div>
                </NamedLink>
                <NamedLink name="SearchPage" to={{ search: '?pub_events=has_all%3Ababy-shower' }}>
                  <div className={css.itemEvent}><div className={css.itemImg}>
                    <img src={BabyShower} alt="Baby Shower" /></div>
                    <span>Baby Shower</span>
                  </div>
                </NamedLink>
                <NamedLink name="SearchPage" to={{ search: '?pub_events=has_all%3Aanniversary' }}>
                  <div className={css.itemEvent}><div className={css.itemImg}>
                    <img src={Anniversary} alt="Anniversary" /></div>
                    <span>Anniversary</span>
                  </div>
                </NamedLink>
                <NamedLink name="SearchPage" to={{ search: '?pub_events=has_all%3Aengagement' }}>
                  <div className={css.itemEvent}><div className={css.itemImg}>
                    <img src={Engagement} alt="Engagement" /></div>
                    <span>Engagement</span>
                  </div>
                </NamedLink>
              </div>}
            >
              <OwlCarousel {...eventOptions}>
                <NamedLink name="SearchPage" to={{ search: '?pub_events=has_all%3Awedding' }}>
                  <div className={css.itemEvent}><div className={css.itemImg}>
                    <img src={Wedding} alt="Wedding" /></div>
                    <span>Wedding</span>
                  </div>
                </NamedLink>
                <NamedLink name="SearchPage" to={{ search: '?pub_events=has_all%3Amarriage' }}>
                  <div className={css.itemEvent}><div className={css.itemImg}>
                    <img src={Marriage} alt="Marriage" /></div>
                    <span>Marriage</span>
                  </div>
                </NamedLink>
                <NamedLink
                  name="SearchPage"
                  to={{ search: '?pub_events=has_all%3Awedding-reception' }}
                >
                  <div className={css.itemEvent}><div className={css.itemImg}>
                    <img src={WeddingReception} alt="Wedding Reception" /></div>
                    <span>Wedding Reception</span>
                  </div>
                </NamedLink>
                <NamedLink name="SearchPage" to={{ search: '?pub_events=has_all%3Abirthday' }}>
                  <div className={css.itemEvent}><div className={css.itemImg}>
                    <img src={Birthday} alt="Birthday" /></div>
                    <span>Birthday</span>
                  </div>
                </NamedLink>
                <NamedLink name="SearchPage" to={{ search: '?pub_events=has_all%3Agraduation' }}>
                  <div className={css.itemEvent}><div className={css.itemImg}>
                    <img src={Graduation} alt="Graduation" /></div>
                    <span>Graduation</span>
                  </div>
                </NamedLink>
                <NamedLink name="SearchPage" to={{ search: '?pub_events=has_all%3Abridal-shower' }}>
                  <div className={css.itemEvent}><div className={css.itemImg}>
                    <img src={BridalShower} alt="Bridal Shower" /></div>
                    <span>Bridal Shower</span>
                  </div>
                </NamedLink>
                <NamedLink name="SearchPage" to={{ search: '?pub_events=has_all%3Ababy-shower' }}>
                  <div className={css.itemEvent}><div className={css.itemImg}>
                    <img src={BabyShower} alt="Baby Shower" /></div>
                    <span>Baby Shower</span>
                  </div>
                </NamedLink>
                <NamedLink name="SearchPage" to={{ search: '?pub_events=has_all%3Aanniversary' }}>
                  <div className={css.itemEvent}><div className={css.itemImg}>
                    <img src={Anniversary} alt="Anniversary" /></div>
                    <span>Anniversary</span>
                  </div>
                </NamedLink>
                <NamedLink name="SearchPage" to={{ search: '?pub_events=has_all%3Aengagement' }}>
                  <div className={css.itemEvent}><div className={css.itemImg}>
                    <img src={Engagement} alt="Engagement" /></div>
                    <span>Engagement</span>
                  </div>
                </NamedLink>
                <NamedLink name="SearchPage" to={{ search: '?pub_events=has_all%3Aretirement' }}>
                  <div className={css.itemEvent}><div className={css.itemImg}>
                    <img src={Retirement} alt="Retirement" /></div>
                    <span>Retirement</span>
                  </div>
                </NamedLink>
                <NamedLink name="SearchPage" to={{ search: '?pub_events=has_all%3Ahouse-warming' }}>
                  <div className={css.itemEvent}><div className={css.itemImg}>
                    <img src={HouseWarming} alt="House Warming" /></div>
                    <span>House Warming</span>
                  </div>
                </NamedLink>
                <NamedLink
                  name="SearchPage"
                  to={{ search: '?pub_events=has_all%3Acocktail-party' }}
                >
                  <div className={css.itemEvent}><div className={css.itemImg}>
                    <img src={CocktailParty} alt="Cocktail Party" /></div>
                    <span>Cocktail Party</span>
                  </div>
                </NamedLink>
                <NamedLink
                  name="SearchPage"
                  to={{ search: '?pub_events=has_all%3Acorporate-event' }}
                >
                  <div className={css.itemEvent}><div className={css.itemImg}>
                    <img src={CorporateEvent} alt="Corporate Event" /></div>
                    <span>Corporate Event</span>
                  </div>
                </NamedLink>
                <NamedLink name="SearchPage" to={{ search: '?pub_events=has_all%3Achristmas' }}>
                  <div className={css.itemEvent}><div className={css.itemImg}>
                    <img src={Christmas} alt="Christmas" /></div>
                    <span>Christmas</span>
                  </div>
                </NamedLink>
                <NamedLink name="SearchPage" to={{ search: '?pub_events=has_all%3Areligious' }}>
                  <div className={css.itemEvent}><div className={css.itemImg}>
                    <img src={Religious} alt="Religious" /></div>
                    <span>Religious</span>
                  </div>
                </NamedLink>
                <NamedLink
                  name="SearchPage"
                  to={{ search: '?pub_events=has_all%3Abachelor-party' }}
                >
                  <div className={css.itemEvent}><div className={css.itemImg}>
                    <img src={BachelorParty} alt="Bachelor Party" /></div>
                    <span>Bachelor Party</span>
                  </div>
                </NamedLink>
                <NamedLink
                  name="SearchPage"
                  to={{ search: '?pub_events=has_all%3Abachelorette-party' }}
                >
                  <div className={css.itemEvent}><div className={css.itemImg}>
                    <img src={BacheloretteParty} alt="Bachelorette Party" /></div>
                    <span>Bachelorette Party</span>
                  </div>
                </NamedLink>
                <NamedLink name="SearchPage" to={{ search: '?pub_events=has_all%3Anew-year' }}>
                  <div className={css.itemEvent}><div className={css.itemImg}>
                    <img src={NewYear} alt="New Year" /></div>
                    <span>New Year</span>
                  </div>
                </NamedLink>
                <NamedLink name="SearchPage" to={{ search: '?pub_events=has_all%3Ahalloween' }}>
                  <div className={css.itemEvent}><div className={css.itemImg}>
                    <img src={Halloween} alt="Halloween" /></div>
                    <span>Halloween</span>
                  </div>
                </NamedLink>
                <NamedLink name="SearchPage" to={{ search: '?pub_events=has_all%3Areunion' }}>
                  <div className={css.itemEvent}><div className={css.itemImg}>
                    <img src={Reunion} alt="Reunion" /></div>
                    <span>Reunion</span>
                  </div>
                </NamedLink>
                <NamedLink name="SearchPage" to={{ search: '?pub_events=has_all%3Aformals' }}>
                  <div className={css.itemEvent}><div className={css.itemImg}>
                    <img src={Formals} alt="Formals" /></div>
                    <span>Formals</span>
                  </div>
                </NamedLink>
                <NamedLink name="SearchPage" to={{ search: '?pub_events=has_all%3Ahens-night' }}>
                  <div className={css.itemEvent}><div className={css.itemImg}>
                    <img src={HensNight} alt="Hen's Night" /></div>
                    <span>Hen's Night</span>
                  </div>
                </NamedLink>
                <NamedLink name="SearchPage" to={{ search: '?pub_events=has_all%3Abucks-night' }}>
                  <div className={css.itemEvent}><div className={css.itemImg}>
                    <img src={BucksNight} alt="Buck's Night" /></div>
                    <span>Buck's Night</span>
                  </div>
                </NamedLink>
                <NamedLink
                  name="SearchPage"
                  to={{ search: '?pub_events=has_all%3Anaming-ceremony' }}
                >
                  <div className={css.itemEvent}><div className={css.itemImg}>
                    <img src={NamingCeremony} alt="Naming Ceremony" /></div>
                    <span>Naming Ceremony</span>
                  </div>
                </NamedLink>
                <NamedLink
                  name="SearchPage"
                  to={{ search: '?pub_events=has_all%3Acommitment-ceremony' }}
                >
                  <div className={css.itemEvent}><div className={css.itemImg}>
                    <img src={CommitmentCeremony} alt="Commitment Ceremony" /></div>
                    <span>Commitment Ceremony</span>
                  </div>
                </NamedLink>
                <NamedLink name="SearchPage" to={{ search: '?pub_events=has_all%3Aphotoshoot' }}>
                  <div className={css.itemEvent}><div className={css.itemImg}>
                    <img src={Photoshoot} alt="Photoshoot" /></div>
                    <span>Photoshoot</span>
                  </div>
                </NamedLink>
                <NamedLink name="SearchPage" to={{ search: '?pub_events=has_all%3Atrade-show' }}>
                  <div className={css.itemEvent}><div className={css.itemImg}>
                    <img src={TradeShow} alt="Trade Show" /></div>
                    <span>Trade Show</span>
                  </div>
                </NamedLink>
                <NamedLink name="SearchPage" to={{ search: '?pub_events=has_all%3Acharity-event' }}>
                  <div className={css.itemEvent}><div className={css.itemImg}>
                    <img src={CharityEvent} alt="Charity Event" /></div>
                    <span>Charity Event</span>
                  </div>
                </NamedLink>
                <NamedLink name="SearchPage" to={{ search: '?pub_events=has_all%3Avip-reception' }}>
                  <div className={css.itemEvent}><div className={css.itemImg}>
                    <img src={VIPReception} alt="VIP Reception" /></div>
                    <span>VIP Reception</span>
                  </div>
                </NamedLink>
                <NamedLink
                  name="SearchPage"
                  to={{ search: '?pub_events=has_all%3Aprivate-dinner' }}
                >
                  <div className={css.itemEvent}><div className={css.itemImg}>
                    <img src={PrivateDinner} alt="Private Dinner" /></div>
                    <span>Private Dinner</span>
                  </div>
                </NamedLink>
                <NamedLink name="SearchPage" to={{ search: '?pub_events=has_all%3Ateam-building' }}>
                  <div className={css.itemEvent}><div className={css.itemImg}>
                    <img src={TeamBuilding} alt="Team Building" /></div>
                    <span>Team Building</span>
                  </div>
                </NamedLink>
                <NamedLink
                  name="SearchPage"
                  to={{ search: '?pub_events=has_all%3Afamily-fun-day' }}
                >
                  <div className={css.itemEvent}><div className={css.itemImg}>
                    <img src={FamilyFunDay} alt="Family Fun Day" /></div>
                    <span>Family Fun Day</span>
                  </div>
                </NamedLink>
                <NamedLink name="SearchPage" to={{ search: '?pub_events=has_all%3Ahenna-party' }}>
                  <div className={css.itemEvent}><div className={css.itemImg}>
                    <img src={HennaParty} alt="Henna Party" /></div>
                    <span>Henna Party</span>
                  </div>
                </NamedLink>
                <NamedLink name="SearchPage" to={{ search: '?pub_events=has_all%3Afestival' }}>
                  <div className={css.itemEvent}><div className={css.itemImg}>
                    <img src={Festival} alt="Festival" /></div>
                    <span>Festival</span>
                  </div>
                </NamedLink>
                <NamedLink
                  name="SearchPage"
                  to={{ search: '?pub_events=has_all%3Ajust-get-together' }}
                >
                  <div className={css.itemEvent}><div className={css.itemImg}>
                    <img src={JustGetTogether} alt="Just Get Together" /></div>
                    <span>Just Get Together</span>
                  </div>
                </NamedLink>
              </OwlCarousel>
            </React.Suspense>
          )}
        </div>
      </div>
      <div className={css.service}>
        <div className={css.containerFluid}>
          <div className={css.title}>
            <div className={css.VisibleDesktop}>
              <img src={our} alt="Our" />
              <h3>Services</h3>
              <div className={css.highlight}>
                <img src={Highlight} alt="Highlight" />
              </div>
              <p>
                Discover a curated collection of exceptional services for every event! <br /> Our talented professionals make your events unforgettable.
              </p>
            </div>
            <div className={css.VisibleMobile}>
              <div className={css.serveiceTitle}>
                <h4>Our Services</h4>
                <NamedLink name="SearchPage">
                  <button type="button" className={css.btnLink}>
                    See all
                  </button>
                </NamedLink>
              </div>
              <p>
                Discover a curated collection of exceptional services for every event! Our talented professionals make your events unforgettable.
              </p>
            </div>
          </div>


          <div className={css.slider}>
            {isClient && (
              <React.Suspense fallback={<div>Loading carousel...</div>}>
                <OwlCarousel {...serviceOptions}>
                  <NamedLink
                    name="SearchPage"
                    to={{ search: '?pub_categoryLevel1=makeup-and-hair-artists' }}
                  >
                    <div className={css.serviceCard}>
                      <img src={Makeup} className={css.shadow} />
                      <img src={Makeup} alt="Makeup and Hair Artists" className={css.image} />
                      <div className={css.footer}>
                        <h5>Makeup and Hair Artists</h5>
                      </div>
                    </div>
                  </NamedLink>

                  <NamedLink
                    name="SearchPage"
                    to={{ search: '?pub_categoryLevel1=photographers-videographers' }}
                  >
                    <div className={css.serviceCard}>
                      <img src={Photographers} className={css.shadow} />
                      <img
                        src={Photographers}
                        alt="Photographers / Videographers"
                        className={css.image}
                      />
                      <div className={css.footer}>
                        <h5>Photographers </h5>
                      </div>
                    </div>
                  </NamedLink>

                  <NamedLink name="SearchPage" to={{ search: '?pub_categoryLevel1=henna-artists' }}>
                    <div className={css.serviceCard}>
                      <img src={HennaArtists} className={css.shadow} />
                      <img src={HennaArtists} alt="Henna Artists" className={css.image} />
                      <div className={css.footer}>
                        <h5>Henna Artists</h5>
                      </div>
                    </div>
                  </NamedLink>

                  <NamedLink name="SearchPage" to={{ search: '?pub_categoryLevel1=catering' }}>
                    <div className={css.serviceCard}>
                      <img src={Catering} className={css.shadow} />
                      <img src={Catering} alt="Catering" className={css.image} />
                      <div className={css.footer}>
                        <h5>Catering</h5>
                      </div>
                    </div>
                  </NamedLink>

                  <NamedLink
                    name="SearchPage"
                    to={{ search: '?pub_categoryLevel1=event-planner-and-coordinator' }}
                  >
                    <div className={css.serviceCard}>
                      <img src={EventPlanner} className={css.shadow} />
                      <img
                        src={EventPlanner}
                        alt="Event Planner & Coordinator"
                        className={css.image}
                      />
                      <div className={css.footer}>
                        <h5>Event Planners</h5>
                      </div>
                    </div>
                  </NamedLink>

                  <NamedLink name="SearchPage" to={{ search: '?pub_categoryLevel1=cook-chef' }}>
                    <div className={css.serviceCard}>
                      <img src={Cook} className={css.shadow} />
                      <img src={Cook} alt="Cook/Chef" className={css.image} />
                      <div className={css.footer}>
                        <h5>Private Chefs</h5>
                      </div>
                    </div>
                  </NamedLink>

                  <NamedLink name="SearchPage" to={{ search: '?pub_categoryLevel1=entertainer' }}>
                    <div className={css.serviceCard}>
                      <img src={Entertainer} className={css.shadow} />
                      <img src={Entertainer} alt="Entertainer" className={css.image} />
                      <div className={css.footer}>
                        <h5>Entertainers </h5>
                      </div>
                    </div>
                  </NamedLink>

                  <NamedLink
                    name="SearchPage"
                    to={{ search: '?pub_categoryLevel1=hospitality-staff' }}
                  >
                    <div className={css.serviceCard}>
                      <img src={Hospitality} className={css.shadow} />
                      <img src={Hospitality} alt="Hospitality Staff" className={css.image} />
                      <div className={css.footer}>
                        <h5>Hospitality Staff </h5>
                      </div>
                    </div>
                  </NamedLink>

                  <NamedLink
                    name="SearchPage"
                    to={{ search: '?pub_categoryLevel1=celebrant-services' }}
                  >
                    <div className={css.serviceCard}>
                      <img src={Celebrant} className={css.shadow} />
                      <img src={Celebrant} alt="Celebrant Services" className={css.image} />
                      <div className={css.footer}>
                        <h5>Celebrants </h5>
                      </div>
                    </div>
                  </NamedLink>

                  <NamedLink name="SearchPage" to={{ search: '?pub_categoryLevel1=cakes' }}>
                    <div className={css.serviceCard}>
                      <img src={Cakes} className={css.shadow} />
                      <img src={Cakes} alt="Cakes" className={css.image} />
                      <div className={css.footer}>
                        <h5>Cakes</h5>
                      </div>
                    </div>
                  </NamedLink>

                  <NamedLink
                    name="SearchPage"
                    to={{ search: '?pub_categoryLevel1=transportation' }}
                  >
                    <div className={css.serviceCard}>
                      <img src={Transportation} className={css.shadow} />
                      <img src={Transportation} alt="Transportation" className={css.image} />
                      <div className={css.footer}>
                        <h5>Transportation </h5>
                      </div>
                    </div>
                  </NamedLink>

                  <NamedLink
                    name="SearchPage"
                    to={{ search: '?pub_categoryLevel1=party-props-and-hire' }}
                  >
                    <div className={css.serviceCard}>
                      <img src={PartyProps} className={css.shadow} />
                      <img src={PartyProps} alt="Party Props & Hire" className={css.image} />
                      <div className={css.footer}>
                        <h5>Party Props & Hire </h5>
                      </div>
                    </div>
                  </NamedLink>

                  <NamedLink name="SearchPage" to={{ search: '?pub_categoryLevel1=venue' }}>
                    <div className={css.serviceCard}>
                      <img src={Venue} className={css.shadow} />
                      <img src={Venue} alt="Venue" className={css.image} />
                      <div className={css.footer}>
                        <h5>Venues</h5>
                      </div>
                    </div>
                  </NamedLink>

                  <NamedLink name="SearchPage" to={{ search: '?pub_categoryLevel1=decorators' }}>
                    <div className={css.serviceCard}>
                      <img src={Decorators} className={css.shadow} />
                      <img src={Decorators} alt="Decorators" className={css.image} />
                      <div className={css.footer}>
                        <h5>Decorators</h5>
                      </div>
                    </div>
                  </NamedLink>
                </OwlCarousel>
              </React.Suspense>
            )}
          </div>

          <div className={css.action}>
            <NamedLink name="SearchPage">
              <button type="button" className={css.brandButton}>
                All Services
              </button>
            </NamedLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionOurTopServices;
